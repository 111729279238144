import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import { localizedPath } from "../i18n/helpers"
import { useLocalization } from "../i18n/hooks/use-localization"

const Link = ({ to, language, ...props }) => {
  const { locale } = useLocalization()
  const linkLocale = language || locale

  return (
    <GatsbyLink
      {...props}
      to={localizedPath({
        localeCode: linkLocale,
        path: to,
      })}
    />
  )
}

export default Link
