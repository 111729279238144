import * as React from "react"
import { LocaleContext } from "../context"
import { getConfig, localizedPath, getDefaultLanguage } from "../helpers"

const useLocalization = () => {
  const locale = React.useContext(LocaleContext)
  const defaultLang = getDefaultLanguage()
  const config = getConfig()

  return {
    locale,
    defaultLang,
    config,
    localizedPath,
  }
}

export { useLocalization }
