exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-cart-js": () => import("./../../../src/pages/my/cart.js" /* webpackChunkName: "component---src-pages-my-cart-js" */),
  "component---src-pages-my-js": () => import("./../../../src/pages/my/[...].js" /* webpackChunkName: "component---src-pages-my-js" */),
  "component---src-views-category-js": () => import("./../../../src/views/category.js" /* webpackChunkName: "component---src-views-category-js" */),
  "component---src-views-product-list-js": () => import("./../../../src/views/product_list.js" /* webpackChunkName: "component---src-views-product-list-js" */),
  "component---src-views-product-page-js": () => import("./../../../src/views/product_page.js" /* webpackChunkName: "component---src-views-product-page-js" */),
  "slice---src-components-header-jsx": () => import("./../../../src/components/Header.jsx" /* webpackChunkName: "slice---src-components-header-jsx" */)
}

