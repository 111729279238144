module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-74HR34K3M7","UA-71098312-3"],"pluginConfig":{"head":false,"respectDNT":false,"exclude":["/preview/**"],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0},"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Struct","short_name":"Struct","description":"Сервіс для пошуку та порівняння цін товарів для дому","scope":"/","lang":"uk","start_url":"/?source=pwa","localize":[{"start_url":"/ru/?source=pwa","lang":"ru","name":"Struct","short_name":"Struct","description":"Сервис для поиска и сравнения цен товаров для дома"}],"display":"standalone","background_color":"#ffffff","theme_color":"#ffffff","icon":"src/images/icons/maskable_icon.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"none","legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/","/ru/"],"workboxConfig":{"globPatterns":["**/icons/*","**/*.{js,jpg,png,html,css}"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
