import styled from "@emotion/styled"

const Wrapper = styled.div`
  width: 100%;
  max-width: ${(275 + 16) * 5 - 16}px;
  margin: 0 auto;
  padding: 2em 0;
`

export default Wrapper
