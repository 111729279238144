import React from "react"
import Layout from "./components/layout"
import { i18n } from "@lingui/core"
import { I18nProvider } from "@lingui/react"
import { LocaleProvider } from "./i18n/context"

const wrapPageElement = ({ element, props }) => {
  const locale = props.pageContext.locale
  const catalog = require(`./i18n/lingui/${locale}/messages.js`)

  i18n.load(locale, catalog.messages)
  i18n.activate(locale)

  return (
    <LocaleProvider pageContext={props.pageContext}>
      <I18nProvider i18n={i18n}>
        <Layout {...props}>{element}</Layout>
        {/* <pre>{JSON.stringify({ ...props }, null, 4)}</pre> */}
      </I18nProvider>
    </LocaleProvider>
  )
}

export { wrapPageElement }
