const theme = {
  colors: {
    primary: {
      50: "#E0E3FF",
      100: "#C2C7FF",
      200: "#8A93FF",
      300: "#4D5BFF",
      400: "#1428FF",
      500: "#0012D6",
      600: "#000D99",
      700: "#000860",
      800: "#000642",
      900: "#00031F",
      950: "#00010F",
    },
    secondary: {
      50: "#FEF0E7",
      100: "#FCE2CF",
      200: "#F9C19A",
      300: "#F7A469",
      400: "#F48739",
      500: "#E9680D",
      600: "#BC550B",
      700: "#8C3F08",
      800: "#5C2905",
      900: "#301603",
      950: "#180B01",
    },
    accent: {
      50: "#ECE0FF",
      100: "#D5BDFF",
      200: "#AB7AFF",
      300: "#843DFF",
      400: "#5C00FA",
      500: "#4300B8",
      600: "#2C0076",
      700: "#200057",
      800: "#16003D",
      900: "#0B001F",
      950: "#06000F",
    },
  },
}

export default theme
