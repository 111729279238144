const config = require("./config.json")

function getConfig() {
  return config
}

function isDefaultLang(lang) {
  return lang === config.defaultLang
}

function localizedPath({ localeCode, path }) {
  // The default language isn't prefixed
  if (
    isDefaultLang(localeCode) &&
    (path === "/" || path === `/offline-plugin-app-shell-fallback/`)
  ) {
    return path
  }

  const [, base] = path.split(`/`)

  // If for whatever reason we receive an already localized path
  // (e.g. if the path was made with location.pathname)
  // just return it as-is.
  if (base === localeCode) {
    return path
  }

  // If it's another language, prefix with the locale
  return `/${localeCode}${path}`
}

function getLocales() {
  return config.locales
}

function getDefaultLocale() {
  return config.locales.find(locale => locale.code === config.defaultLang)
}

function getDefaultLanguage() {
  return config.defaultLang
}

module.exports = {
  getConfig,
  localizedPath,
  getLocales,
  getDefaultLocale,
  getDefaultLanguage,
}
