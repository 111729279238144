import * as React from "react"
import { getDefaultLanguage } from "./helpers"

const defaultLang = getDefaultLanguage()
const LocaleContext = React.createContext(defaultLang)

const LocaleProvider = ({
  children,
  pageContext: { locale = defaultLang },
}) => <LocaleContext.Provider value={locale}>{children}</LocaleContext.Provider>

export { LocaleContext, LocaleProvider }
