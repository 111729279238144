import * as React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { Slice } from "gatsby"

import Footer from "./Footer"
// import Waves from "./Waves"
import "./layout.css"

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  :before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    background-color: #ffffff;
    opacity: 0.8;
    background-image: linear-gradient(
        to top,
        rgb(255, 255, 255) 40%,
        rgba(255, 255, 255, 0.75)
      ),
      radial-gradient(
        closest-side,
        rgb(255, 255, 255) 25%,
        rgba(255, 255, 255, 0) 90%
      ),
      linear-gradient(#444cf7 2px, transparent 2px),
      linear-gradient(90deg, #444cf7 2px, transparent 2px),
      linear-gradient(#444cf7 1px, transparent 1px),
      linear-gradient(90deg, #444cf7 1px, #e5e5f7 1px);
    background-size: 100% 100%, 100% 100%, 200px 200px, 200px 200px, 40px 40px,
      40px 40px;
    background-position: 0 0, 0 0, -1px -1px, -1px -1px, -1px -1px, -1px -1px,
      50% 50%;
    z-index: -1;
  }
`

const PageContent = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const Layout = ({ children, ...props }) => {
  return (
    <PageWrapper>
      <Slice alias="header" />
      <PageContent>{children}</PageContent>
      <Footer originalPath={props.pageContext.originalPath} />
      {/* <Waves /> */}
    </PageWrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
